'use strict'

###*
 # @ngdoc object
 # @name mundoLive.service:LiveMapService

 # @description

###
class LiveMapService
  ### @ngInject ###
  constructor:(
    $log
    $filter
    $q
    MundoMap
    $document
    $window
    $rootScope
    ColorService
  ) ->

    ## Get the openlayer object from the window
    ol = $window.ol

    # Reset seed_colors while switching Ctrls
    ColorService.resetSeedColors()

    ## Returns an ol map instance create with the MundoMap Factory
    @getMap = (mapId) ->
      MundoMap.createInstance mapId,
        layers: [
          #   _layerType: 'Vector'
          #   _layerId: 'markers'
          #   _weight: 50
          #   _clusterable: false
          #   _searchable: false
          #   _zoomable: true
          #   _updateWhileMoving: false
          #   title: 'Markers'
          #   visible: true
          #   source: [
          #     'Vector'
          #       features: []
          #   ]
          #   style: null
          # ,
        ]
        follow:
          objects: []
          enabled: false
          speedZoom: false
        fillScreen: true
        search:
          enabled: false

    ## Returns the layer style for the marker layer
    @getMarkerLayerStyle = (feature) ->
      mainFeature = feature;

      # Check whether this feature is a cluster or not
      cluster = feature.get('features')?

      if cluster
        # Set mainFeature default to first
        mainFeature = feature.get('features')[0]
        size = feature.get('features').length

        # If the cluster size is larger than 1 (an actual cluster),
        # return a cluster style
        if size > 1
          # If selected tab look for selectedSize
          if $rootScope.showSelection? && $rootScope.showSelection
            selectedSize = 0
            # Calculate the size of selected in cluster and the last selectedFeature as mainFeature
            # (if multiple this doesnt matter because of cluster style)
            angular.forEach feature.get('features'), (childFeature) ->
              if childFeature._selected
                selectedSize++
                mainFeature = childFeature

            if selectedSize > 1
              # Show cluster style if selectedsize bigger then 1
              style = feature.get('_cachedStyle')
              if not style
                style = new ol.style.Style
                  image: new ol.style.Circle
                    radius: 15
                    stroke: new ol.style.Stroke
                      color: 'rgba(255,255,255,0.8)'
                      width: 2
                    fill: new ol.style.Fill
                      color: '#3399CC'
                  text: new ol.style.Text
                    text: selectedSize.toString()
                    scale: 1.5
                    fill: new ol.style.Fill
                      color: '#FFFFFF'

              feature.set '_cachedStyle', style
              return style

          else
            style = feature.get('_cachedStyle')
            if not style
              style = new ol.style.Style
                image: new ol.style.Circle
                  radius: 15
                  stroke: new ol.style.Stroke
                    color: 'rgba(255,255,255,0.8)'
                    width: 2
                  fill: new ol.style.Fill
                    color: '#3399CC'
                text: new ol.style.Text
                  text: size.toString()
                  scale: 1.5
                  fill: new ol.style.Fill
                    color: '#FFFFFF'

            feature.set '_cachedStyle', style
            return style

      bgColor = mainFeature.get('_meta').color
      fillColor = 'rgba(255,255,255,0.8)'
      if mainFeature._selected
        fillColor = 'rgba(40,255,40,0.9)'

      textColor = '#eeeeee'

      opacity = 1

      if mainFeature._selected
        fillColor = 'rgba(40,255,40,0.9)'

      if ($rootScope.showSelection? && $rootScope.showSelection)
        if mainFeature._selected
          opacity = 1
        else
          opacity = 0.1
          fillColor = 'rgba(255,255,255,0)'

      style = mainFeature.get('_cachedStyle')

      featureType = mainFeature.get('_field')

      if not style
        if (($rootScope.showSelection? && $rootScope.showSelection) && mainFeature._selected) \
        or (!$rootScope.showSelection)
          style = [
            new ol.style.Style
              image: new ol.style.Circle
                radius: 15
                stroke: new ol.style.Stroke
                  color: 'rgba(0, 0, 0, 0.8)'
                  width: 3
                  opacity: opacity
                fill: new ol.style.Fill
                  color: fillColor
                  opacity: opacity
            new ol.style.Style
              image: new ol.style.Circle
                opacity: opacity
                radius: 15
                stroke: new ol.style.Stroke
                  color: bgColor
                  width: 2
              text: new ol.style.Text
                opacity: opacity
                text: mainFeature.get('label')
                scale: 1.5
                offsetY: 18
                fill: new ol.style.Fill
                  color: 'white'
                backgroundFill: new ol.style.Fill
                  color: bgColor
                padding: [1, 1, 1, 1]
            new ol.style.Style
              image: new ol.style.Icon
                anchor: [0.5, 0.5]
                anchorXUnits: 'fraction'
                anchorYUnits: 'fraction'
                snapToPixel: true
                opacity: opacity
                crossOrigin: 'anonymous'
                src: mainFeature.get('_meta').mapMarkerUrl
                color: bgColor
          ]

          mainFeature.set '_cachedStyle', style

      return style

    @addStatusToMap = (
      status,
      features,
      layer,
      statuses,
      visible = true,
      heatmap
    ) ->
      promise = new Promise (resolve, reject) ->
        if status.location.point
          # Initial declaration and getting of some important variables
          unit = status.unit
          location = status.location
          status.type = 'status'
          asset = status.asset
          timestamp = status.timestamp.event

          mapMarkerUrl = MundoMap.getMarkerPath unit.id

          # Parse the point to geometry
          geometry = MundoMap.getGeometryFromGeoJSON location.point

          # Parse stuff for searching
          tags = [unit.label]

          # Check if there is not yet a feature for this unit
          feature = features[unit.id]

          # Create a new feature
          if not feature
            feature = new ol.Feature
              geometry: geometry
              name: unit.label or unit.id
              _meta:
                color: '#' + ColorService.getColorBySeed(unit.id)
                mapMarkerUrl: mapMarkerUrl

            features[unit.id] = feature

            if visible
              MundoMap.getLayerSource(layer).addFeature(feature)
              MundoMap.getLayerSource(heatmap).addFeature(feature)
          else

          feature.setGeometry geometry
          feature.set '_status', status
          feature.set '_tags', tags

          feature._tags = tags
          feature._ios = status.io
          feature._contacts = status.contacts
          feature._label = unit.label

          angular.forEach feature._ios, (io) ->
            classes = []
            classes.push (if io && io.value then 'io-active' else 'io-inactive')

            if io && io.tags?
              (classes.push "io-tag-#{x}" for x in io.tags)

            io.class = classes

          feature.set 'label', unit.label
          feature.set '_cachedStyle', null
          statuses[unit.id] = status

          return resolve
        else
          return resolve
      return promise

    # This zooms the map in on a bunch of features.
    # @ todo: add duration as parameter with a default
    @zoomMapToFeatures = (map, features, duration = 500) ->
      extent = ol.extent.createEmpty()
      angular.forEach features, (feature)->
        ol.extent.extend extent, feature.getGeometry().getExtent()
      map
        .getView()
        .fit extent,
          duration: duration
          padding: [100, 100, 100, 100]
          constrainResolution: true

    @clearLayer = (layer) ->
      layer.getSource().clear()
      layer.getSource().refresh()

    @refreshLayer = (layer) ->
      layer.getSource().refresh()


angular
  .module('mundoLive')
  .service 'LiveMapService', LiveMapService
